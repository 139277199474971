import React from 'react';
import { HostArr, BeiAnNumberEnum, MeiTuanYXHost } from '../constans';

const BeiAn: React.FC = () => {
    const host = window?.location?.href;
    // eslint-disable-next-line no-nested-ternary
    const beianNumber = HostArr.some(item => host.indexOf(item) > -1)
        ? BeiAnNumberEnum.else
        : host.indexOf(MeiTuanYXHost) > -1
        ? BeiAnNumberEnum.default
        : BeiAnNumberEnum.elseif;
    return (
        <a
            rel="noopener noreferrer"
            className="yz_test"
            target="_blank"
            href="https://beian.miit.gov.cn"
        >
            {beianNumber}
        </a>
    );
};
export default BeiAn;
