import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { observer, inject } from 'mobx-react';
import { createHashHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import cssObj from './css';
import stores from '../../stores';
// import logoBlack from '../../assets/images/logo-black.png';
// import logoWhite from '../../assets/images/logo-white.png';

const hashHistory = createHashHistory({
    basename: process.env.prefix,
});

const routerStore = new RouterStore();

const history = syncHistoryWithStore(hashHistory, routerStore);

interface MenuItem {
    title: string;
    router: string;
}

const Head: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { navBarList, pathname, isTranparentHeader, headLogo } = props.data;

        useEffect(() => {
            history.push(pathname);
            // stores.data.isTranparentHeaderChange(pathname.indexOf('home') >= 0);
        }, [pathname]);

        return (
            <div
                css={css`
                    ${cssObj.header}
                    ${isTranparentHeader ? cssObj.transparentHeader : ''}
                `}
            >
                <div
                    css={css`
                        ${cssObj.logo}
                    `}
                >
                    {/* <img src={isTranparentHeader ? logoWhite : logoBlack} alt="" /> */}
                    <img src={headLogo} alt="" />
                </div>
                <div
                    css={css`
                        ${cssObj.navBar}
                    `}
                >
                    {navBarList.map((_: MenuItem) => {
                        const isActive =
                            (_.router === '/' && pathname === '/') ||
                            (_.router !== '/' && pathname.indexOf(_.router) > -1);

                        return (
                            <div
                                key={_.router}
                                css={[
                                    css`
                                        ${cssObj.navItem}
                                    `,
                                    isActive
                                        ? css`
                                              ${cssObj.navActive}
                                          `
                                        : '',
                                ]}
                                onClick={(): void => {
                                    stores.data.pathnameChange(`${_.router}`);
                                }}
                            >
                                {_.title}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }),
);

export default Head;
