export default {
    quality: `
  background: #F8F8F8;
  margin-top: 70px;
  min-height: 830px;
  `,
    qualityContent: `
  width: 1000px;
  min-height: 742px;
  background: #FFFFFF;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  margin: 30px 0 58px;
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  `,
    qualityWapper: `
  padding: 30px 24px;
  `,
    qualityBread: `
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #000000;
  line-height: 36px;
  font-weight: 400;
  display: inline-block;
  border-bottom: 3px solid #FFD100;
  position: relative;
    &:after {
      content: "";
      display: block;
      width: 952px;
      height: 1px;
      background: #F5F5F5;
      position: absolute;
      bottom: -2px;
      left: 0;
      z-index: -1;
    }
  `,
    qualityList: `
  margin-top: 12px;
  `,
    qualityTh: `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background: #F5F5F5;
  border-radius: 4px;
  height: 40px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #888888;
  line-height: 28px;
  font-weight: 400;
  `,
    emptyQuality: `
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #888888;
  `,
    qualityItem: `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:51px;
  cursor: pointer;
  padding: 0 24px;
    &:not(:last-child) {
      border-bottom: 1px solid #F5F5F5;
    }
  `,
};
