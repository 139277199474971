import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import request from '../../utils/request';
import ComponentHead from '../../components/head';
import ComponentFoot from '../../components/foot';
import stores from '../../stores';

const Wrapper: React.FC = inject(...['data'])(
    observer((props: any) => {
        useEffect(() => {
            request
                .get({
                    url: 'horn',
                    params: { version: 'v1', from: 'official_config' },
                })
                .then((res: any) => {
                    const {
                        navBarList,
                        serviceIntro,
                        leaderConfig,
                        cooperationConfig,
                        recruitmentConfig,
                        orderConfig,
                        qualityConfig,
                        cityCoordinateDict,
                        footConfig,
                        homeConfig,
                        headLogo,
                        footLogo,
                    } = res?.data?.customer || {};

                    stores.data.navBarListChange(navBarList);
                    stores.data.serviceIntroChange(serviceIntro);

                    stores.data.leaderConfigChange(leaderConfig);
                    stores.data.footConfigChange(footConfig);

                    stores.data.cityCoordinateDictChange(cityCoordinateDict);

                    stores.data.homeConfigChange(homeConfig);
                    stores.data.cooperationConfigChange(cooperationConfig);
                    stores.data.recruitmentConfigChange(recruitmentConfig);
                    stores.data.orderConfigChange(orderConfig);
                    stores.data.qualityConfigChange(qualityConfig);

                    stores.data.headLogoChange(headLogo);
                    stores.data.footLogoChange(footLogo);
                });
        }, []);

        return (
            <div>
                <ComponentHead />
                {props.children}
                <ComponentFoot />
            </div>
        );
    }),
);

export default Wrapper;
