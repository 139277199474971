import './global.scss';
import {
    // env,
    lx,
    // query as api,
    useRequestInterceptor,
    IrequestConfig,
    catReport,
    logan,
} from '@lsfe/utils';
import { AxiosError } from 'axios';
import Toast from '@lsfe/d/lib/toast';

import logger from './utils/logger';

// const isMock = api.getOne('mock');

catReport.init('mall-game-orchard');
useRequestInterceptor(
    (config: IrequestConfig) => {
        config.toast = Toast;
        // if (isMock) {
        //     config.url = `/mock${config.url}`;
        // }
        return config;
    },
    (failMsg: AxiosError) => {
        console.log(failMsg);
    },
);
// env.init();
lx.initSDK({ appnm: 'meituanyouxuan_officeweb', category: 'youxuan' });
logan.init();

try {
    logger.info(window.navigator.userAgent);
} catch (e) {}
