export default {
    introduceItem: `
    &:not(:first-of-type) {
      margin-top: 60px;
    }
`,
    introduceItemTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #5B2B1F;
  line-height: 46px;
  position: relative;
  img {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 50%;
    left: -36px;
    margin-top: -13px;
  }
`,
    introduceItemDescription: `
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5B2B1F;
  line-height: 30px;
  margin-top: 6px;
`,
    introduceContent: `
  display: flex;
  justify-content: center;
`,
    introduceDesciption: `
  width: 381px;
  margin-right: 6px;
`,
    introduceCover: `
  width: 578px;
  height: 540px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
`,
};
