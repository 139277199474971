import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';

import cssObj from './css';

const Qualification: React.FC = inject(...['data'])(
    observer(() => {
        const [qualificationSrc] = useState(
            'https://img.meituan.net/groceryapi/cac897f1fdf8520696b35c795e922f88409776.jpg',
        );
        const [huangxiaodouSrc] = useState(
            'https://img.meituan.net/groceryapi/7e177d6f46e9f35011d4952062a483bc320679.jpg',
        );

        useEffect(() => {
            document.documentElement.scrollTop = 0;
            // lx.view('b_youxuan_phz7qvml_mv');
        }, []);

        return (
            <div
                css={css`
                    ${cssObj.container}
                `}
            >
                <img src={qualificationSrc} width="740" height="440" />
                <img src={huangxiaodouSrc} width="600" height="400" />
            </div>
        );
    }),
);

export default Qualification;
