import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';

import stores from '../../stores';
import cssObj from './css';

const Quality: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { qualityConfig } = props.data;

        const [quality, setQuality] = useState(
            {} as {
                title: string;
                detail: {
                    paragraph: Array<string>;
                    from: string;
                    to: string;
                    time: string;
                };
            },
        );

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;

            lx.page('c_youxuan_s4ppf2md');
        }, []);

        useEffect(() => {
            const { list = [] } = qualityConfig || {};
            const { pathname } = props.history.location;
            const id = pathname.slice(pathname.lastIndexOf('/') + 1);
            const quality =
                list.filter((item: any) => {
                    return item.detail.id === id;
                })[0] || {};

            setQuality(quality);
        }, [qualityConfig]);

        console.log(quality);

        return (
            <div
                css={css`
                    ${cssObj.quality}
                `}
            >
                <div
                    css={css`
                        ${cssObj.qualityContent}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.qualityWapper}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.qualityBread}
                            `}
                        >
                            <span>公告列表 &gt; 公告详情</span>
                        </div>
                        {quality.title ? (
                            <div
                                css={css`
                                    ${cssObj.qualityTitle}
                                `}
                            >
                                {quality.title}
                            </div>
                        ) : (
                            ''
                        )}
                        {quality.detail ? (
                            <div
                                css={css`
                                    ${cssObj.qualityDetail}
                                `}
                            >
                                <div>{quality?.detail?.to}</div>
                                {quality?.detail?.paragraph.map((item: any, idx: number): any => {
                                    return (
                                        <div
                                            key={idx}
                                            css={css`
                                                ${cssObj.qualityParagraph}
                                            `}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                                <div
                                    css={css`
                                        ${cssObj.from}
                                    `}
                                >
                                    {quality?.detail?.from}
                                </div>
                                <div
                                    css={css`
                                        ${cssObj.time}
                                    `}
                                >
                                    {quality?.detail?.time}
                                </div>
                            </div>
                        ) : (
                            <div
                                css={css`
                                    ${cssObj.emptyQuality}
                                `}
                            >
                                暂无公告内容
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }),
);

export default Quality;
