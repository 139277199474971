export default {
    cooperation: `
    margin-top: 70px;
  `,
    cooperationIntro: `
    height: 610px;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
  `,
    cooperationIntroBox: `
    background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
    border-radius: 4px;
    width: 1000px;
    padding: 32px 0 24px;
    position: absolute;
    top: -130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  `,
    cooperationIntroTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 26px;
  color: #5B2B1F;
  text-align: center;
  line-height: 26px;
  `,
    cooperationIntroContent: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #5B2B1F;
  text-align: center;
  line-height: 28px;
  width: 549px;
  margin: 20px auto 0;
  `,
    cooperationIntroQrcode: `
  width: 100px;
  height: 100px;
  display: block;
  margin: 10px auto 0;
  img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }
  `,
    process: `
  padding: 178px 0 82px;
  background: #F8F8F8;
  position: relative;
  `,
    processTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 40px;
  `,
    processContent: `
    display: flex;
    justify-content: center;
    margin-top: 68px;
  `,
    processItem: `
  width: 266px;
  background: #FFFFFF;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  padding-bottom: 23px;
  position: relative;
  &:not(:last-child) {
    margin-right: 100px;
  }
  `,
    processItemTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #5B2B1F;
  text-align: center;
  line-height: 24px;
  padding: 28px 0;
  background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
  `,
    processItemSubTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #000000;
  text-align: center;
  line-height: 24px;
  margin-top: 40px;
  `,
    processItemContent: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 28px;
  margin-top: 16px;
  height: 56px;
  `,
    processItemCover: `
  width: 100px;
  height: 100px;
  margin: 19px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  `,
    connect: `
  width: 38px;
  height: 10px;
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  `,
};
