export default {
    debounce: (time: number, fn: Function): Function => {
        let timer: any = null;

        return (): any => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
                fn();
            }, time);
        };
    },
    getConvertData: (
        data: Array<{ name: string; value: number }>,
        geoCoordMap: Record<string, Array<number>>,
    ): any => {
        const res = [];
        for (let i = 0; i < data.length; i++) {
            const geoCoord = geoCoordMap[data[i].name];

            if (geoCoord) {
                res.push(geoCoord.concat(data[i].value));
            }
        }

        return res;
    },
};
