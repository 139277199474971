export default {
    header: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.06);
    padding: 0 220px;
    color: #333;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: all .1s;
  `,
    transparentHeader: `
    // background: transparent;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 10;
    // color: #fff;
    // box-shadow: none
  `,
    logo: `
    width: 138px;
    height: 46px;
    img {
      width: 100%;
      height: 100%;
    }
  `,
    navBar: `
    display: flex;
    justify-content: space-between;
  `,
    navItem: `
    text-align: center;
    font-size: 16px;
    cursor: pointer;
      &:not(:last-child) {
        margin-right: 40px;
      }
  `,
    navActive: `
    position: relative;
    box-sizing: border-box;
    font-weight: bold;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -27px;
        width: 100%;
        height: 3px;
        background: #FFD100;
      }
  `,
};
