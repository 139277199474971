import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';
import stores from '../../stores';

import iconConnect from '../../assets/images/icon-connect.png';

import cssObj from './css';

const Cooperation: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { cooperationConfig } = props.data;
        const [description, setDescription] = useState('');
        const [mpQrcode, setMpQrcode] = useState('');
        const [processList, setProcessList] = useState([]);

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;
            lx.view('b_youxuan_phz7qvml_mv');
        }, []);

        useEffect(() => {
            const { description = '', processList = [], mpQrcode } =
                cooperationConfig.content || {};

            setDescription(description);
            setProcessList(processList);
            setMpQrcode(mpQrcode);
        }, [cooperationConfig]);

        return (
            <div
                css={css`
                    ${cssObj.cooperation}
                `}
            >
                <div
                    css={css`
                        ${cssObj.cooperationIntro}
                    `}
                >
                    <img src={cooperationConfig.cover} alt="" />
                </div>
                <div
                    css={css`
                        ${cssObj.process}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.cooperationIntroBox}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.cooperationIntroTitle}
                            `}
                        >
                            {cooperationConfig.title}
                        </div>
                        <div
                            css={css`
                                ${cssObj.cooperationIntroContent}
                            `}
                        >
                            {description}
                        </div>
                        <div
                            css={css`
                                ${cssObj.cooperationIntroQrcode}
                            `}
                        >
                            <img src={mpQrcode} alt="" />
                        </div>
                    </div>
                    <div
                        css={css`
                            ${cssObj.processTitle}
                        `}
                    >
                        入驻流程
                    </div>
                    <div
                        css={css`
                            ${cssObj.processContent}
                        `}
                    >
                        {processList.map((_: any, idx: number) => {
                            return (
                                <div
                                    key={_.id}
                                    css={css`
                                        ${cssObj.processItem}
                                    `}
                                >
                                    <div
                                        css={css`
                                            ${cssObj.processItemTitle}
                                        `}
                                    >
                                        {_.title}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.processItemSubTitle}
                                        `}
                                    >
                                        {_.subTitle}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.processItemContent}
                                        `}
                                    >
                                        {_.content}
                                    </div>
                                    {idx < processList.length - 1 ? (
                                        <div
                                            css={css`
                                                ${cssObj.connect}
                                            `}
                                        >
                                            <img src={iconConnect} alt="" />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }),
);

export default Cooperation;
