import axios from 'axios';

axios.defaults.timeout = 10000;
axios.defaults.baseURL = 'https://portal-portm.meituan.com/';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

axios.interceptors.request.use(
    config => {
        const isDev: boolean = process.env.NODE_ENV !== 'production';
        if (config.url !== 'horn') {
            return config;
        }

        if (isDev) {
            config.params.os = 'android_test';
        } else {
            config.params.os = 'android';
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    res => {
        if (res.status === 200) {
            return Promise.resolve(res);
        }

        return Promise.reject(res);
    },
    err => {
        return Promise.reject(err.response);
    },
);

interface GetIprops {
    url: string;
    params: object;
}

interface PostIprops {
    url: string;
    data: object;
}

const axiosGet: any = (props: GetIprops) => {
    return new Promise((resolve, reject) => {
        axios
            .get(props.url, { params: props.params })
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

const axiosPost: any = (props: PostIprops) => {
    return new Promise((resolve, reject) => {
        axios
            .post(props.url, props.data)
            .then(data => {
                resolve(data);
            })
            .catch(err => {
                reject(err);
            });
    });
};

export default {
    get: axiosGet,
    post: axiosPost,
};
