export default {
    home: `
    width: 100%;
    margin-top: 70px;
    .swiper-container-horizontal {
      .swiper-pagination-bullets {
        bottom: 91px;
      }
    }
  `,
    img: `
    width: 100%;
    height: 610px;
    overflow:hidden;
    display: block;
    img {
      width: 100%;
    }
  `,
    swiper: `
    position: relative;
    height: 610px;
    overflow: hidden;
  `,
    introImg: `
    width: 100%;
    display: block;
  `,
    description: `
    font-size: 68px;
    color: #FFFFFF;
    letter-spacing: 2.2px;
    position: absolute;
    left: 220px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -10px;
    z-index: 5;
  `,
    subdescription: `
    margin-top: 22px;
    margin-left: 70px;
  `,
    entryItem: `
    width: 333px;
    padding: 56px 0;
    height: 174px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .1s;
    position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background: inherit;
        transition: all .1s;
      }
      &:hover {
        &::before {
          box-shadow: 0 25px 20px -15px rgba(101,142,247,.25);
          transform: scaleY(1.05);
        }
      }
  `,
    introduce: `
    padding: 170px 0 65px 0;
    background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
    position: relative;
  `,
    entry: `
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -60px;
    z-index: 5;
    display: flex;
  `,
    entryIcon: `
    width: 30px;
    height: 30px;
    margin-right: 8px;
     img {
       width: 100%;
       height: 100%;
       display: block;
     }
  `,
    entryTitle: `
    font-family: PingFangSC-Semibold;
    font-size: 26px;
    color: #FFFFFF;
    text-align: center;
    line-height: 26px;
    margin-right: 10px;
  `,
    iconArrowRight: `
    width: 7px;
    height: 14px;
    opacity: 0.5;
     img {
       width: 100%;
       height: 100%;
       display: block;
     }
  `,
    entryDescription: `
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: rgba(255,255,255,0.69);
    text-align: center;
    line-height: 16px;
    margin-top: 16px;
  `,
    entryTitleWrapper: `
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
  `,
    introduceTitle: `
    width: 384px;
    height: 68px;
    position: relative;
    font-family: PingFangSC-Semibold;
    font-size: 40px;
    color: #5B2B1F;
    text-align: center;
    line-height: 40px;
    margin: 0 auto 81px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      div {
        position: relative;
        transform: translateY(38px)
      }
  `,
    cityPlan: `
      padding: 73px 0 16px 0;
  `,
    cityPlanTitle: `
    width: 230px;
    height: 65px;
    position: relative;
    font-family: PingFangSC-Semibold;
    font-size: 40px;
    color: #5B2B1F;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      div {
        position: relative;
        transform: translateY(38px)
      }
  `,
    cityPlanDescription: `
    width: 818px;
    font-family: MicrosoftYaHei;
    font-size: 22px;
    color: #000000;
    text-align: center;
    line-height: 36px;
    margin: 50px auto 0;
  `,
    cityMap: `
    width: 700px;
    height: 577px;
    margin: 0 auto;
    overflow: hidden;
  `,
    workProfit: `
    padding:72px 0 82px 0;
    background: #F8F8F8;
  `,
    workProfitTitle: `
    width: 285px;
    height: 65px;
    position: relative;
    font-family: PingFangSC-Semibold;
    font-size: 40px;
    color: #5B2B1F;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      div {
        position: relative;
        transform: translateY(38px)
      }
  `,
    workProfitContent: `
    display: flex;
    justify-content: center;
    margin: 78px 0 0;
  `,
    workProfitItem: `
    width: 485px;
    height: 600px;
    overflow: hidden;
    background-image: linear-gradient(40deg, #FF8249 0%, #FFD100 100%);
    border-radius: 4px;
    position: relative;
    &:first-of-type {
      margin-right: 30px;
    }
      img {
        width: 100%;
        height: 390px;
      }
  `,
    workProfitItemBox: `
    width: 445px;
    height: 278px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 40px 0 0 40px;
    line-height: 0;
  `,
    workProfitItemTitle: `
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #000000;
    line-height: 24px;
  `,
    workProfitItemContent: `
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #888888;
    letter-spacing: -0.3px;
    line-height: 28px;
    width: 320px;
    margin-top: 16px;
    `,
    button: `
    padding: 9px 19px;
    background: #FFD100;
    border-radius: 100px;
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    color: #5B2B1F;
    line-height: 12px;
    display: inline-block;
    margin-top: 16px;
    cursor: pointer;
    &:hover {
      background: #F5C900;
    }
    `,
    news: `
      padding: 73px 0;
        .swiper-container-horizontal {
          .swiper-pagination-bullets {
            bottom: 0;
          }
        }
    `,
    newsTitle: `
    width: 260px;
    height: 65px;
    position: relative;
    font-family: PingFangSC-Semibold;
    font-size: 40px;
    color: #5B2B1F;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      div {
        position: relative;
        transform: translateY(38px)
      }
    `,
    newsContent: `
      margin-top: 68px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    newsItem: `
      display: flex;
      justify-content: center;
    `,
    newsItemCover: `
      width: 414px;
      height: 566px;
      margin-right: 70px;
      position: relative;
      padding-bottom: 52px;
        img {
          width: 378px;
          height: 530px;
          object-fit: cover;
          position: relative;
          z-index: 2;
          border-radius: 4px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 36px;
          left: 32px;
          z-index: 1;
          width: 382px;
          height: 530px;
          background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
          border-radius: 4px;
        }
    `,
    newsItemBox: ``,
    newsItemDate: `
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #000000;
    line-height: 24px;
    `,
    newsItemTitle: `
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #000000;
    line-height: 24px;
    margin-top: 12px;
    position: relative;
    &:after {
      content: "";
      width: 58px;
      height: 3px;
      position: absolute;
      background: #3D3D3D;
      left: 0;
      bottom: -20px;
    }
    `,
    newsItemContent: `
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #888888;
    line-height: 28px;
    width: 505px;
    margin-top: 37px;
    `,
    newsItemContentParagraph: `
      &:not(:first-of-type) {
        margin-top: 30px;
      }
    `,
};
