export default {
    recruitment: `
  margin-top: 70px;
  `,
    intro: `
    height: 370px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.3);
    }
  `,
    introContent: `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
  `,
    introTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  margin-top: 90px;
  `,
    introDescription: `
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  line-height: 28px;
  margin-top: 20px;
`,
    button: `
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #5B2B1F;
  text-align: center;
  line-height: 22px;
  padding: 12px 30px;
  background: #FFD100;
  box-shadow: 0 0 18px 0 rgba(0,0,0,0.08);
  border-radius: 23px;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
  &:hover {
    background: #F5C900;
  }
  `,
    hotOffer: `
  padding: 50px 0 61px;
  `,
    hotOfferTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 40px;
  `,
    hotOfferContent: `
  display: flex;
  justify-content: center;
  margin: 50px 0 40px;
  `,
    hotOfferItem: `
  width: 227px;
  background: #FFFFFF;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  margin-right: 30px;
  `,
    hotOfferDirection: `
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #5B2B1F;
  line-height: 24px;
  padding: 28px 20px 28px;
  background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
  `,
    hotOfferJobList: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000000;
  line-height: 28px;
  padding: 18px 18px 18px 20px;
  `,
};
