const geoCoordMap: Record<string, Array<number>> = {
    甘肃: [103.73, 36.03],
    青海: [101.74, 36.56],
    四川: [104.06, 30.67],
    河北: [114.48, 38.03],
    云南: [102.73, 25.04],
    贵州: [106.71, 26.57],
    湖北: [114.31, 30.52],
    河南: [113.65, 34.76],
    山东: [117, 36.65],
    江苏: [118.78, 32.04],
    安徽: [117.27, 31.86],
    浙江: [120.19, 30.26],
    江西: [115.89, 28.68],
    福建: [119.3, 26.08],
    广东: [113.23, 23.16],
    湖南: [113, 28.21],
    海南: [110.35, 20.02],
    辽宁: [123.38, 41.8],
    吉林: [125.35, 43.88],
    黑龙江: [126.63, 45.75],
    山西: [112.53, 37.87],
    陕西: [108.95, 34.27],
    台湾: [121.3, 25.03],
    北京: [116.46, 39.92],
    上海: [121.48, 31.22],
    重庆: [106.54, 29.59],
    天津: [117.2, 39.13],
    内蒙古: [111.65, 40.82],
    广西: [108.33, 22.84],
    西藏: [91.11, 29.97],
    宁夏: [106.27, 38.47],
    新疆: [87.68, 43.77],
    香港: [114.17, 22.28],
    澳门: [113.54, 22.19],
};

export const convertData = (data: Array<{ name: string; value: number }>): any => {
    const res = [];
    for (let i = 0; i < data.length; i++) {
        const geoCoord = geoCoordMap[data[i].name];

        if (geoCoord) {
            res.push(geoCoord.concat(data[i].value));
        }
    }

    return res;
};

export default {
    tooltip: {
        trigger: 'item',
        show: false,
    },
    legend: {
        orient: 'vertical',
        left: 'left',
        data: [],
        backgroundColor: '#fff',
    },
    visualMap: {
        color: ['#FFD100'],
        backgroundColor: '#fff',
        show: false,
    },
    geo: {
        map: 'china',
        label: {
            emphasis: {
                show: false,
            },
        },
        itemStyle: {
            normal: {
                areaColor: '#f6f6f6',
                borderWidth: '#111',
            },
            emphasis: {
                areaColor: '#f6f6f6',
            },
        },
    },
    series: [
        {
            name: '已开城',
            mapType: 'china',
            type: 'scatter',
            coordinateSystem: 'geo',
            itemStyle: {
                areaColor: '#f6f6f6',
                borderWidth: 0,
            },
            emphasis: {
                itemStyle: {
                    areaColor: '#f6f6f6',
                },
            },
            label: {
                normal: {
                    show: false,
                },
                emphasis: {
                    show: false,
                },
            },
            symbolSize: 40,
            symbol:
                'image://https://p0.meituan.net/mallimages/271fb7ba325645d39ebeb4a70c03cb342171.png',
            data: [],
            tooltip: {
                trigger: 'item',
                show: true,
                backgroundColor: '#fff',
                textStyle: {
                    color: '#000',
                    fontFamily: 'MicrosoftYaHei',
                    fontSize: 20,
                },
                formatter: (params: any): string => {
                    const [x, y, value] = params.data,
                        keys = Object.keys(geoCoordMap);

                    let str = `${value} 美团优选入驻`;

                    keys.forEach(k => {
                        if (geoCoordMap[k][0] === x && geoCoordMap[k][1] === y) {
                            str += k;
                        }
                    });

                    return str;
                },
                padding: [8, 18],
            },
        },
    ],
};
