import React from 'react';
import { css } from '@emotion/core';
import { observer, inject } from 'mobx-react';
import cssObj from './css';
import policeIcon from '../../assets/images/police-icon.png';
import BeiAn from './beian';
import { MeiTuanYXHost, HostArr } from './constans';

// import logo from '../../assets/images/logo-white.png';

const Foot: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { footConfig, footLogo } = props.data;
        const host = window?.location?.href;
        const isNewHost = HostArr.some(item => host.indexOf(item) > -1);
        const isMeiTuanYXHost = host.indexOf(MeiTuanYXHost) > -1;
        const {
            miniQrcode = '',
            commonQrcode = '',
            // clientServerPhone,
            protocolList = [],
            clietnServerTime = '',
        } = footConfig;
        return (
            <div
                css={css`
                    ${cssObj.footer}
                `}
            >
                <div
                    css={css`
                        ${cssObj.baseInfo}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.footerLogo}
                        `}
                    >
                        <img src={footLogo} />
                        <div>关于我们</div>
                        {protocolList.map((protocol: any, idx: number) => {
                            return (
                                <div key={idx}>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={protocol.url}
                                    >
                                        {protocol.title}
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        css={css`
                            ${cssObj.footerInfo}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.clientService}
                            `}
                        >
                            客服 4001801234
                        </div>
                        <div
                            css={css`
                                ${cssObj.serviceTime}
                            `}
                        >
                            {((): any => {
                                const arr: Array<string> = clietnServerTime.split('/');
                                return arr.map((_: string, idx: number): any => {
                                    return <div key={idx}>{_}</div>;
                                });
                            })()}
                        </div>
                    </div>
                    <div
                        css={css`
                            ${cssObj.footerQrCode}
                        `}
                    >
                        <div>更方便，更实惠</div>
                        <div
                            css={css`
                                ${cssObj.qrcode}
                            `}
                        >
                            <div>
                                <img src={miniQrcode} alt="" />
                            </div>
                            {commonQrcode ? (
                                <div>
                                    <img src={commonQrcode} alt="" />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        ${cssObj.recordInfo}
                    `}
                >
                    {isNewHost ? (
                        <div
                            css={css`
                                ${cssObj.record}
                            `}
                            style={{
                                paddingBottom: '10px',
                            }}
                        >
                            {' '}
                            <div>
                                <BeiAn />
                            </div>
                            <div>ICP许可证号：粤B2-20211708</div>
                        </div>
                    ) : (
                        <>
                            <div
                                css={css`
                                    ${cssObj.record}
                                `}
                            >
                                <div>{isMeiTuanYXHost ? '深圳美团优选科技有限公司' : ''}</div>
                                <div>
                                    {isMeiTuanYXHost
                                        ? '深圳市龙华区民治街道北站社区汇隆商务中心2号楼1105'
                                        : ''}
                                </div>
                                <div>
                                    {isMeiTuanYXHost ? '' : '@2015 meituan.com 京ICP证070791号'}
                                </div>
                                <div>
                                    <BeiAn />
                                </div>
                                <div
                                    css={css`
                                        ${cssObj.recordNumber}
                                    `}
                                >
                                    <img src={policeIcon} alt="" />
                                    {isMeiTuanYXHost ? (
                                        <a
                                            target="_blank"
                                            href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002001117"
                                            rel="noopener noreferrer"
                                        >
                                            粤公网安备44030002001117号
                                        </a>
                                    ) : (
                                        <a
                                            target="_blank"
                                            href="https://beian.mps.gov.cn/#/query/webSearch?code=11000002002052号"
                                            rel="noopener noreferrer"
                                        >
                                            京公网安备11000002002052号
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div
                                css={css`
                                    ${cssObj.certificate}
                                `}
                            >
                                互联网药品信息服务资格证书编号 （粤）-经营性-2021-0039
                            </div>
                            <div
                                css={css`
                                    ${cssObj.certificate}
                                `}
                            >
                                医疗器械网络交易服务第三方平台备案编号
                                （粤）网械平台备字（2021）第00002号
                            </div>
                            <div
                                css={css`
                                    ${cssObj.cetWrapper}
                                `}
                            >
                                <div
                                    onClick={(): void => {
                                        window.location.hash = '/qualification';
                                    }}
                                    css={css`
                                        ${cssObj.customDiv}
                                    `}
                                >
                                    平台营业执照
                                </div>
                                <div
                                    css={css`
                                        ${cssObj.ml10}
                                        ${cssObj.customDiv}
                                    `}
                                    onClick={(): void => {
                                        window.location.hash = '/edi';
                                    }}
                                >
                                    平台EDI许可证
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }),
);

export default Foot;
