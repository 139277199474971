import React from 'react';
import { css } from '@emotion/core';
import { observer, inject } from 'mobx-react';
import cssObj from './css';

const IntroduceContent: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { serviceIntro } = props.data;

        return (
            <div
                css={css`
                    ${cssObj.introduceContent}
                `}
            >
                <div
                    css={css`
                        ${cssObj.introduceDesciption}
                    `}
                >
                    {(serviceIntro.introduce || []).map((_: any) => {
                        return (
                            <div
                                css={css`
                                    ${cssObj.introduceItem}
                                `}
                                key={_.id}
                            >
                                <div
                                    css={css`
                                        ${cssObj.introduceItemTitle}
                                    `}
                                >
                                    <div>
                                        <img src={_.icon} alt="" />
                                    </div>
                                    <div>{_.title}</div>
                                </div>
                                <div
                                    css={css`
                                        ${cssObj.introduceItemDescription}
                                    `}
                                >
                                    {_.description}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    css={css`
                        ${cssObj.introduceCover}
                    `}
                >
                    <img src={serviceIntro.cover} alt="" />
                </div>
            </div>
        );
    }),
);

export default IntroduceContent;
