import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { css } from '@emotion/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { lx } from '@lsfe/utils';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import echarts from 'echarts/lib/echarts';

import iconArrowRight from '../../assets/images/icon-arrow-right.png';
import introduceTitleBg from '../../assets/images/service-bg.png';
import palnTitleBg from '../../assets/images/plan-bg.png';
import workProfitTitleBg from '../../assets/images/work-bg.png';
import newsBg from '../../assets/images/news-bg.png';

import 'swiper/swiper.scss';
import cssObj from './css';
import stores from '../../stores';
import IntroduceContent from '../../components/introduceContent';

import 'swiper/components/pagination/pagination.scss';

import utils from '../../utils/utils';

import './index.scss';

import mapOption from './config';

import './china.js';

require('echarts/lib/component/tooltip');
require('echarts/lib/chart/map');

SwiperCore.use([Pagination, Autoplay, Navigation]);

const Home: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { homeConfig, cityCoordinateDict } = props.data;

        const {
            introImgList = [],
            description = '',
            entryList = [],
            cityPlanDescription = '',
            cityList = [],
            workProfitList = [],
            newsList = [],
        } = homeConfig;

        const [descrip, setDescrip] = useState(description);

        const gotoPage = (router: string): void => {
            const { history } = props;

            if (/^(https|http)/.test(router)) {
                window.open(router);
                return;
            }

            document.documentElement.scrollTop = 0;
            history.push(router);
        };

        useEffect(() => {
            lx.page('c_youxuan_9k1ttfsk');
        }, []);

        const NewsItem: React.FC = (props: any) => {
            const { cover, date, title, content, url } = props;

            return (
                <div
                    css={css`
                        ${cssObj.newsItem}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.newsItemCover}
                        `}
                    >
                        <img src={cover} alt="" />
                    </div>
                    <div
                        css={css`
                            ${cssObj.newsItemBox}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.newsItemDate}
                            `}
                        >
                            {date}
                        </div>
                        <div
                            css={css`
                                ${cssObj.newsItemTitle}
                            `}
                        >
                            {title}
                        </div>
                        <div
                            css={css`
                                ${cssObj.newsItemContent}
                            `}
                        >
                            {((): any => {
                                const arr: Array<string> = content.split('/');

                                return arr.map((_, idx) => {
                                    return (
                                        <div
                                            css={css`
                                                ${cssObj.newsItemContentParagraph}
                                            `}
                                            key={idx}
                                        >
                                            {_}
                                        </div>
                                    );
                                });
                            })()}
                        </div>
                        <div
                            css={css`
                                ${cssObj.button}
                            `}
                            onClick={(): void => {
                                gotoPage(url);
                            }}
                        >
                            查看报道
                        </div>
                    </div>
                </div>
            );
        };

        const cityMapRef: React.RefObject<HTMLDivElement> = React.createRef();

        useEffect(() => {
            const myChart = echarts.init(cityMapRef.current as HTMLDivElement);

            mapOption.series[0].data = utils.getConvertData(cityList, cityCoordinateDict);

            myChart.setOption(mapOption as {}, true);
        }, [cityList, cityCoordinateDict]);

        /* const scrollListener = (): void => {
            const fn: any = utils.debounce(50, () => {
                const { history } = props;

                if (history.location.pathname.indexOf('home') >= 0) {
                    const scrollTop: number =
                        window.pageYOffset ||
                        document.documentElement.scrollTop ||
                        document.body.scrollTop;

                    stores.data.isTranparentHeaderChange(!scrollTop);
                }
            });

            window.addEventListener('scroll', fn);
        }; */

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;
            stores.data.isTranparentHeaderChange(false);
            // scrollListener();
        }, []);

        useEffect(() => {
            const idx: number = description.indexOf('/');

            if (idx >= 0) {
                setDescrip(
                    <div>
                        <div>{description.slice(0, idx)}</div>
                        <div
                            css={css`
                                ${cssObj.subdescription}
                            `}
                        >
                            {description.slice(idx + 1)}
                        </div>
                    </div>,
                );
            }
        }, [description]);

        return (
            <div
                css={css`
                    ${cssObj.home}
                `}
            >
                {introImgList.length > 1 ? (
                    <Swiper
                        loop
                        css={css`
                            ${cssObj.swiper}
                        `}
                        speed={5000}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            disableOnInteraction: false,
                        }}
                        grabCursor
                    >
                        {introImgList.map((_: { image: string; id: number }) => {
                            return (
                                <SwiperSlide key={_.id}>
                                    <img
                                        css={css`
                                            ${cssObj.introImg},
                                        `}
                                        src={_.image}
                                        alt=""
                                    />
                                </SwiperSlide>
                            );
                        })}
                        <div
                            css={css`
                                ${cssObj.description}
                            `}
                        >
                            {descrip}
                        </div>
                    </Swiper>
                ) : (
                    <div
                        css={css`
                            ${cssObj.img}
                        `}
                    >
                        {introImgList.length ? <img src={introImgList[0].image} alt="" /> : ''}
                    </div>
                )}
                <div
                    css={css`
                        ${cssObj.introduce}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.entry}
                        `}
                    >
                        {entryList.map((_: any) => {
                            return (
                                <div
                                    css={css`
                                        ${cssObj.entryItem}
                                    `}
                                    key={_.id}
                                    style={{ background: _.color }}
                                    onClick={(): void => {
                                        gotoPage(_.router);
                                    }}
                                >
                                    <div
                                        css={css`
                                            ${cssObj.entryTitleWrapper}
                                        `}
                                    >
                                        <div
                                            css={css`
                                                ${cssObj.entryIcon}
                                            `}
                                        >
                                            <img src={_.icon} alt="" />
                                        </div>
                                        <div
                                            css={css`
                                                ${cssObj.entryTitle}
                                            `}
                                        >
                                            {_.title}
                                        </div>
                                        <div
                                            css={css`
                                                ${cssObj.iconArrowRight}
                                            `}
                                        >
                                            <img src={iconArrowRight} alt="" />
                                        </div>
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.entryDescription}
                                        `}
                                    >
                                        {_.description}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        css={css`
                            ${cssObj.introduceTitle}
                        `}
                    >
                        <img src={introduceTitleBg} alt="" />
                        <div>美团优选介绍</div>
                    </div>
                    <IntroduceContent />
                </div>
                <div
                    css={css`
                        ${cssObj.cityPlan}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.cityPlanTitle}
                        `}
                    >
                        <img src={palnTitleBg} alt="" />
                        <div>千城计划</div>
                    </div>
                    <div
                        css={css`
                            ${cssObj.cityPlanDescription}
                        `}
                    >
                        {cityPlanDescription}
                    </div>
                    <div
                        ref={cityMapRef}
                        css={css`
                            ${cssObj.cityMap}
                        `}
                    />
                </div>
                <div
                    css={css`
                        ${cssObj.workProfit}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.workProfitTitle}
                        `}
                    >
                        <img src={workProfitTitleBg} alt="" />
                        <div>合作优势</div>
                    </div>
                    <div
                        css={css`
                            ${cssObj.workProfitContent}
                        `}
                    >
                        {workProfitList.map((_: any) => {
                            return (
                                <div
                                    css={css`
                                        ${cssObj.workProfitItem}
                                    `}
                                    key={_.id}
                                >
                                    <img src={_.cover} alt="" />
                                    <div
                                        css={css`
                                            ${cssObj.workProfitItemBox}
                                        `}
                                    >
                                        <div
                                            css={css`
                                                ${cssObj.workProfitItemTitle}
                                            `}
                                        >
                                            {_.title}
                                        </div>
                                        <div
                                            css={css`
                                                ${cssObj.workProfitItemContent}
                                            `}
                                        >
                                            {_.content}
                                        </div>
                                        <div
                                            css={css`
                                                ${cssObj.button}
                                            `}
                                            onClick={(): void => {
                                                gotoPage(_.router);
                                            }}
                                        >
                                            {_.btnText}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    css={css`
                        ${cssObj.news}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.newsTitle}
                        `}
                    >
                        <img src={newsBg} alt="" />
                        <div>媒体新闻报道</div>
                    </div>
                    <div
                        className="newsContent"
                        css={css`
                            ${cssObj.newsContent}
                        `}
                    >
                        {newsList.length >= 2 ? <div className="swiper-button-prev" /> : ''}

                        {newsList.length >= 2 ? (
                            <Swiper
                                loop
                                speed={5000}
                                pagination={{
                                    clickable: true,
                                }}
                                loopPreventsSlide={false}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                style={{ width: '70%' }}
                                autoplay={{
                                    disableOnInteraction: false,
                                }}
                            >
                                {newsList.map((_: any) => {
                                    return (
                                        <SwiperSlide key={_.id}>
                                            <NewsItem {..._} />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        ) : (
                            <div>{newsList.length ? <NewsItem {...newsList[0]} /> : ''}</div>
                        )}
                        {newsList.length >= 2 ? <div className="swiper-button-next" /> : ''}
                    </div>
                </div>
            </div>
        );
    }),
);

export default Home;
