import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';
import stores from '../../stores';

import iconConnect from '../../assets/images/icon-connect.png';

import cssObj from './css';

const Leader: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { leaderConfig } = props.data;
        const [description, setDescription] = useState('');
        const [promiseList, setPromiseList] = useState([]);
        const [applyStepList, setApplyStepList] = useState([]);

        const leaderRef: React.RefObject<HTMLDivElement> = React.createRef();

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;
            lx.view('b_youxuan_hjeotlcl_mv');
        }, []);

        useEffect(() => {
            const { description = '', promiseList = [], applyStepList = [] } =
                leaderConfig.content || {};

            setDescription(description);
            setPromiseList(promiseList);
            setApplyStepList(applyStepList);
        }, [leaderConfig]);

        return (
            <div
                css={css`
                    ${cssObj.leader}
                `}
            >
                <div
                    css={css`
                        ${cssObj.leaderIntro}
                    `}
                >
                    <img src={leaderConfig.cover} alt="" />
                </div>
                <div
                    css={css`
                        ${cssObj.promise}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.leaderIntroBox}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.leaderIntroTitle}
                            `}
                        >
                            {leaderConfig.title}
                        </div>
                        <div
                            css={css`
                                ${cssObj.leaderIntroContent}
                            `}
                        >
                            {description}
                        </div>
                        <div
                            css={css`
                                ${cssObj.button}
                            `}
                            onClick={(): void => {
                                (leaderRef.current as HTMLDivElement).scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }}
                        >
                            成为团长
                        </div>
                    </div>
                    <div
                        css={css`
                            ${cssObj.promiseTitle}
                        `}
                    >
                        三重保障
                    </div>

                    <div
                        css={css`
                            ${cssObj.promiseContent}
                        `}
                    >
                        {promiseList.map((_: any) => {
                            return (
                                <div
                                    key={_.id}
                                    css={css`
                                        ${cssObj.promiseItem}
                                    `}
                                >
                                    <div
                                        css={css`
                                            ${cssObj.promiseItemIcon}
                                        `}
                                    >
                                        <img src={_.icon} alt="" />
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.promiseItemTitle}
                                        `}
                                    >
                                        {_.title}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.promiseItemContent}
                                        `}
                                    >
                                        {_.content}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    css={css`
                        ${cssObj.apply}
                    `}
                    ref={leaderRef}
                >
                    <div
                        css={css`
                            ${cssObj.applyTitle}
                        `}
                    >
                        申请方法
                    </div>
                    <div
                        css={css`
                            ${cssObj.applyContent}
                        `}
                    >
                        {applyStepList.map((_: any, idx: number) => {
                            return (
                                <div
                                    key={_.id}
                                    css={css`
                                        ${cssObj.applyItem}
                                    `}
                                >
                                    <div
                                        css={css`
                                            ${cssObj.applyItemTitle}
                                        `}
                                    >
                                        {_.title}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.applyItemContent}
                                        `}
                                    >
                                        {((): any => {
                                            const arr: Array<string> = _.content.split('/');

                                            return arr.map((item, idx) => {
                                                return <div key={idx}>{item}</div>;
                                            });
                                        })()}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.applyItemCover}
                                        `}
                                    >
                                        <img src={_.picture} alt="" />
                                    </div>
                                    {idx < applyStepList.length - 1 ? (
                                        <div
                                            css={css`
                                                ${cssObj.connect}
                                            `}
                                        >
                                            <img src={iconConnect} alt="" />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }),
);

export default Leader;
