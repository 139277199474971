import { action, observable } from 'mobx';
import { createHashHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import 'mobx-react-lite/batchingForReactDom';

const hashHistory = createHashHistory({
    basename: process.env.prefix,
});

const routerStore = new RouterStore();

const history = syncHistoryWithStore(hashHistory, routerStore);

export class Store {
    @observable
    headLogo = '';

    @observable
    footLogo = '';

    @observable
    navBarList: Array<object> = [];

    @observable
    pathname = history.location.pathname;

    @observable
    isTranparentHeader = history.location.pathname.indexOf('home') >= 0;

    @observable
    serviceIntro: object = {};

    @observable
    leaderConfig: object = {};

    @observable
    cooperationConfig: object = {};

    @observable
    recruitmentConfig: object = {};

    @observable
    orderConfig: object = {};

    @observable
    qualityConfig: object = {};

    @observable
    cityCoordinateDict: object = {};

    @observable
    footConfig: object = {};

    @observable
    homeConfig: object = {};

    @action
    navBarListChange(navBarList: Array<object>): void {
        this.navBarList = navBarList;
    }

    @action
    pathnameChange(pathname: string): void {
        this.pathname = pathname;
    }

    @action
    isTranparentHeaderChange(isTranparentHeader: boolean): void {
        this.isTranparentHeader = isTranparentHeader;
    }

    @action
    serviceIntroChange(serviceIntro: Array<object>): void {
        this.serviceIntro = serviceIntro;
    }

    @action
    leaderConfigChange(leaderConfig: object): void {
        this.leaderConfig = leaderConfig;
    }

    @action
    cooperationConfigChange(cooperationConfig: object): void {
        this.cooperationConfig = cooperationConfig;
    }

    @action
    recruitmentConfigChange(recruitmentConfig: object): void {
        this.recruitmentConfig = recruitmentConfig;
    }

    @action
    orderConfigChange(orderConfig: object): void {
        this.orderConfig = orderConfig;
    }

    @action
    cityCoordinateDictChange(cityCoordinateDict: object): void {
        this.cityCoordinateDict = cityCoordinateDict;
    }

    @action
    footConfigChange(footConfig: object): void {
        this.footConfig = footConfig;
    }

    @action
    qualityConfigChange(qualityConfig: object): void {
        this.qualityConfig = qualityConfig;
    }

    @action
    homeConfigChange(homeConfig: object): void {
        this.homeConfig = homeConfig;
    }

    @action
    headLogoChange(headLogo: ''): void {
        this.headLogo = headLogo;
    }

    @action
    footLogoChange(footLogo: ''): void {
        this.footLogo = footLogo;
    }
}

export default { data: new Store() };
