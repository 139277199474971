export default {
    order: `
  margin-top: 70px;
  `,
    orderIntro: `
  height: 370px;
  width: 100%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
  }
  `,
    introContent: `
  position: absolute;
  top: 0;
  right: 280px;
  z-index: 3;
  `,
    introTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #FFFFFF;
  line-height: 40px;
  margin-top: 70px;
  `,
    introDescription: `
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
  margin-top: 20px;
  `,
    mpQrcode: `
  border-radius: 6px;
  width: 140px;
  height: 140px;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 6px;
  }
  `,
    orderContent: `
  padding: 54px 0 55px;
  background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
  `,
    goodsList: `
  background: #F8F8F8;
  padding: 70px 0 60px;
  `,
    goodsTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 40px;
  `,
    goodsContent: `
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.04);
  border-radius: 21px;
  margin: 40px auto 0;
  padding: 40px 100px;
  box-sizing: border-box;
  `,
    goodsItem: `
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #121924;
  text-align: center;
  line-height: 20px;
  width: 80px;
  margin-right: 100px;
  &:nth-of-type(5n) {
    margin-right: 0;
  }
  &:not(:nth-of-type(-n + 5))  {
    margin-top: 52px;
  }
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  `,
};
