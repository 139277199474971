import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createHashHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Provider } from 'mobx-react';
import stores from '../../stores';
import Wrapper from '../wrapper';

/* 懒加载会有体验问题 */
/* const Home = React.lazy(() => import('../home'));
const Leader = React.lazy(() => import('../leader'));
const Order = React.lazy(() => import('../order'));
const Recruitment = React.lazy(() => import('../recruitment'));
const Cooperation = React.lazy(() => import('../cooperation')); */

import Home from '../home';
import Leader from '../leader';
import Order from '../order';
import Recruitment from '../recruitment';
import Quality from '../quality';
import QualityDetail from '../quality-detail';
import Cooperation from '../cooperation';
import Qualification from '../certification/qualification';
import Edi from '../certification/edi';

const hashHistory = createHashHistory({
    basename: process.env.prefix,
});

const routerStore = new RouterStore();

const history = syncHistoryWithStore(hashHistory, routerStore);

ReactDOM.render(
    <Provider {...stores}>
        <Router history={history}>
            <Wrapper>
                <Suspense fallback="">
                    <Switch>
                        <Route path="/cooperation" component={Cooperation} />
                        <Route path="/leader" component={Leader} />
                        <Route path="/order" component={Order} />
                        <Route path="/recruitment" component={Recruitment} />
                        <Route path="/quality/:id" component={QualityDetail} />
                        <Route path="/quality" component={Quality} />
                        <Route path="/qualification" component={Qualification} />
                        <Route path="/edi" component={Edi} />
                        <Route path="/" component={Home} />
                        <Redirect from="/*" to="/" />
                    </Switch>
                </Suspense>
            </Wrapper>
        </Router>
    </Provider>,
    document.getElementById('root'),
);
