export default {
    leader: `
    margin-top: 70px;
  `,
    leaderIntro: `
    height: 610px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      display: block;
    }
  `,
    leaderIntroBox: `
    background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
    border-radius: 4px;
    width: 1000px;
    padding: 32px 0 24px;
    position: absolute;
    top: -130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  `,
    leaderIntroTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 26px;
  color: #5B2B1F;
  text-align: center;
  line-height: 26px;
  `,
    leaderIntroContent: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #5B2B1F;
  text-align: center;
  line-height: 28px;
  width: 549px;
  margin: 20px auto 0;
  `,
    button: `
  border: 1px solid #5B2B1F;
  padding: 11px 35px;
  border-radius: 100px;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #5B2B1F;
  line-height: 20px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  &:hover {
    background: rgba(91,43,31,0.07);
  }
  `,
    promise: `
  padding: 160px 0 60px;
  position: relative;
  `,
    promiseTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 40px;
  `,
    promiseContent: `
    display: flex;
    justify-content: center;
  `,
    promiseItem: `
  width: 224px;
  &:not(:last-child) {
    margin-right: 164px
  }
  `,
    promiseItemIcon: `
  width: 140px;
  height: 140px;
  margin: 68px auto 0;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
  `,
    promiseItemTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #000000;
  text-align: center;
  line-height: 24px;
  margin-top: 30px;
  `,
    promiseItemContent: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #888888;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 28px;
  margin-top: 16px;
  `,
    apply: `
  padding: 70px 0 82px;
  background: #F8F8F8;
  `,
    applyTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 40px;
  color: #000000;
  text-align: center;
  line-height: 40px;
  `,
    applyContent: `
    display: flex;
    justify-content: center;
    margin-top: 68px;
  `,
    applyItem: `
  width: 266px;
  background: #FFFFFF;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.04);
  border-radius: 4px;
  padding-bottom: 23px;
  position: relative;
  &:not(:last-child) {
    margin-right: 100px;
  }
  `,
    applyItemTitle: `
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #5B2B1F;
  text-align: center;
  line-height: 24px;
  padding: 28px 0;
  background-image: linear-gradient(-135deg, #FFE019 0%, #FFD100 100%);
  `,
    applyItemContent: `
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 28px;
  margin-top: 22px;
  height: 56px;
  `,
    applyItemCover: `
  width: 100px;
  height: 100px;
  margin: 19px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  `,
    connect: `
  width: 38px;
  height: 10px;
  position: absolute;
  right: -70px;
  top: 50%;
  transform: translateY(-50%);
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  `,
};
