import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';

import stores from '../../stores';
import cssObj from './css';

const Quality: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { qualityConfig } = props.data;

        const [qualityList, setQualityList] = useState([]);

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;

            lx.page('c_youxuan_s4ppf2md');
        }, []);

        useEffect(() => {
            const { list = [] } = qualityConfig || {};

            setQualityList(list);
        }, [qualityConfig]);

        const viewDetail = (detail: any): any => {
            const { history } = props;

            document.documentElement.scrollTop = 0;

            history.push(`/quality/${detail.id}`);
        };

        return (
            <div
                css={css`
                    ${cssObj.quality}
                `}
            >
                <div
                    css={css`
                        ${cssObj.qualityContent}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.qualityWapper}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.qualityBread}
                            `}
                        >
                            <span>公告列表</span>
                            {/* <span>公告列表 &gt; 公告详情</span> */}
                        </div>
                        <div
                            css={css`
                                ${cssObj.qualityList}
                            `}
                        >
                            <div
                                css={css`
                                    ${cssObj.qualityTh}
                                `}
                            >
                                <div>公告标题</div>
                                <div>公告时间</div>
                            </div>
                            <div>
                                {qualityList.length ? (
                                    qualityList.map((quality: any, idx: number) => {
                                        return (
                                            <div
                                                onClick={(): any => {
                                                    viewDetail(quality.detail);
                                                }}
                                                key={idx}
                                                css={css`
                                                    ${cssObj.qualityItem}
                                                `}
                                            >
                                                <div>{quality.title}</div>
                                                <div>{quality.time}</div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div
                                        css={css`
                                            ${cssObj.emptyQuality}
                                        `}
                                    >
                                        暂无公告
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }),
);

export default Quality;
