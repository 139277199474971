import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';

import IntroduceContent from '../../components/introduceContent';
import stores from '../../stores';
import cssObj from './css';

const Order: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { orderConfig } = props.data;
        const [goodsList, setGoodsList] = useState([]);

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;
            lx.view('b_youxuan_x0cighyt_mv');
        }, []);

        useEffect(() => {
            const { goodsList = [] } = orderConfig;

            setGoodsList(goodsList);
        }, [orderConfig]);

        return (
            <div
                css={css`
                    ${cssObj.order}
                `}
            >
                <div
                    css={css`
                        ${cssObj.orderIntro}
                    `}
                >
                    <img src={orderConfig.cover} alt="" />
                    <div
                        css={css`
                            ${cssObj.introContent}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.introTitle}
                            `}
                        >
                            {orderConfig.title}
                        </div>
                        <div
                            css={css`
                                ${cssObj.introDescription}
                            `}
                        >
                            {orderConfig.description}
                        </div>
                        <div
                            css={css`
                                ${cssObj.mpQrcode}
                            `}
                        >
                            <img src={orderConfig.mpQrcode} alt="" />
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        ${cssObj.orderContent}
                    `}
                >
                    <IntroduceContent />
                </div>
                <div
                    css={css`
                        ${cssObj.goodsList}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.goodsTitle}
                        `}
                    >
                        {orderConfig.goodsTitle}
                    </div>
                    <div
                        css={css`
                            ${cssObj.goodsContent}
                        `}
                    >
                        {goodsList.map((_: any) => {
                            return (
                                <div
                                    css={css`
                                        ${cssObj.goodsItem}
                                    `}
                                    key={_.id}
                                >
                                    <img src={_.picture} alt="" />
                                    <div>{_.title}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }),
);

export default Order;
