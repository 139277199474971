export default {
    footer: `
    height: 319px;
    background: #333333;
    position: relative;
    font-size: 22px;
    color: #EEEEEE;
    line-height: 22px;
    padding-top: 52px;
    box-sizing: border-box;
  `,
    baseInfo: `
    display: flex;
    justify-content: center;
  `,
    recordInfo: `
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #666;
    font-size: 12px;
    color: #888888;
    padding: 10px 0;
    a {
      color: #888;
      text-decoration: none;
    }
  `,
    record: `
  display: flex;
  justify-content: center;
  align-items: center;
    > div {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  `,
    footerLogo: `
    margin-right: 150px;
    height: 36px;
    font-size: 14px;
    color: #AAAAAA;
    line-height: 24px;
    font-family: PingFangSC-Regular;
      img {
        width: 108px;
      }
      a {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-decoration: none;
      }
  `,
    footerInfo: `
    margin-right: 150px;
  `,
    footerQrCode: `
  
  `,
    clientService: `
  
  `,
    serviceTime: `
    margin-top: 9px;
    font-size: 14px;
    color: #AAAAAA;
    line-height: 24px;
  `,
    qrcode: `
    display: flex;
    margin-top: 17px;
      > div {
        width: 77px;
        height: 77px;
        border: 3px solid #ddd;
        &:not(:last-child) {
          margin-right: 14px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
  `,
    recordNumber: `
      display: flex;
      justify-content: center;
      align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
        }
  `,
    certificate: `
      text-align: center;
      margin-top: 5px;
  `,
    cetWrapper: `
        display: flex;
        margin-top: 5px;
        justify-content: center;
    `,
    ml10: `margin-left: 10px;`,
    customDiv: `cursor: pointer;`,
};
