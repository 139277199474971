import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { lx } from '@lsfe/utils';

import stores from '../../stores';
import cssObj from './css';

const Recruitment: React.FC = inject(...['data'])(
    observer((props: any) => {
        const { recruitmentConfig } = props.data;

        const [description, setDescription] = useState('');
        const [url, setUrl] = useState('');
        const [offerList, setOfferList] = useState([]);

        useEffect(() => {
            stores.data.pathnameChange(props.history.location.pathname);
            document.documentElement.scrollTop = 0;

            lx.view('b_youxuan_ahyhu5u8_mv');
        }, []);

        useEffect(() => {
            const { description = '', offerList = [], url = '' } = recruitmentConfig.content || {};

            setDescription(description);
            setOfferList(offerList);
            setUrl(url);
        }, [recruitmentConfig]);

        return (
            <div
                css={css`
                    ${cssObj.recruitment}
                `}
            >
                <div
                    css={css`
                        ${cssObj.intro}
                    `}
                >
                    <img src={recruitmentConfig.cover} alt="" />
                    <div
                        css={css`
                            ${cssObj.introContent}
                        `}
                    >
                        <div
                            css={css`
                                ${cssObj.introTitle}
                            `}
                        >
                            {recruitmentConfig.title}
                        </div>
                        <div
                            css={css`
                                ${cssObj.introDescription}
                            `}
                        >
                            {((): any => {
                                const arr: Array<string> = description.split('/');

                                return arr.map((_: any, idx: number) => {
                                    return <div key={idx}>{_}</div>;
                                });
                            })()}
                        </div>
                        <div
                            css={css`
                                ${cssObj.button}
                            `}
                            onClick={(): void => {
                                window.open(url);
                            }}
                        >
                            加入美团优选
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        ${cssObj.hotOffer}
                    `}
                >
                    <div
                        css={css`
                            ${cssObj.hotOfferTitle}
                        `}
                    >
                        热招岗位
                    </div>
                    <div
                        css={css`
                            ${cssObj.hotOfferContent}
                        `}
                    >
                        {offerList.map((_: any) => {
                            return (
                                <div
                                    css={css`
                                        ${cssObj.hotOfferItem}
                                    `}
                                    key={_.id}
                                >
                                    <div
                                        css={css`
                                            ${cssObj.hotOfferDirection}
                                        `}
                                    >
                                        {_.direction}
                                    </div>
                                    <div
                                        css={css`
                                            ${cssObj.hotOfferJobList}
                                        `}
                                    >
                                        {((): any => {
                                            const arr: Array<string> = _.jobList.split('/');

                                            return arr.map((_: any, idx: number): any => {
                                                return <div key={idx}>{_}</div>;
                                            });
                                        })()}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        css={css`
                            ${cssObj.button}
                        `}
                        onClick={(): void => {
                            window.open(url);
                        }}
                    >
                        加入美团优选
                    </div>
                </div>
            </div>
        );
    }),
);

export default Recruitment;
