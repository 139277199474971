import { observer, inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';

import cssObj from './css';

const Edi: React.FC = inject(...['data'])(
    observer(() => {
        // const [qualificationSrc] = useState('https://img.meituan.net/groceryapi/5ccc177e8039859039deb47e41d66a25550525.jpg');
        const [edi1] = useState(
            'https://img.meituan.net/groceryapi/ac4842d2ee5e78f637809b03b19ebf91331753.jpg',
        );
        const [edi2] = useState(
            'https://img.meituan.net/groceryapi/9a86231962caa8eb5daf5a3604ee5636290832.jpg',
        );

        useEffect(() => {
            document.documentElement.scrollTop = 0;
            // lx.view('b_youxuan_phz7qvml_mv');
        }, []);

        return (
            <div
                css={css`
                    ${cssObj.container}
                `}
            >
                {/* <img src={qualificationSrc} width="600" height="800" /> */}
                <img src={edi1} width="600" height="800" />
                <img src={edi2} width="600" height="800" />
            </div>
        );
    }),
);

export default Edi;
